import React from 'react';
import MethaqRefundForm from "./custom-forms/methaq-refund-form";
import {useMutation} from "react-query";
import {
    REQUEST_PAYMENT_REFUND
} from "../../../utils/graphql";
import {apollo} from "../../../utils/apollo";
import {toast} from "react-toastify";
import useModalStore from "../../../store/modal";
import use2FAStatus from "../../../hooks/use2FAStatus";
import Button from "../../../components/Button";
import DefaultRefundForm from "./custom-forms/default-refund-form";
import {clearCachedKey} from "../../../utils/helpers";

function RefundForm({payment}) {
    const {setModal, clearModal, hideModal, isOtpRequired, getOtpValue} = useModalStore();
    const is2FAEnabled = use2FAStatus();
    const [formData, setFormData] = React.useState({});
    const [customFormErrors, setCustomFormErrors] = React.useState([]);

    const handleRefundAction = () => {
        if (is2FAEnabled?.isLoading) {
            toast.warn('Two-factor authentication status is still loading. Please try again.');
            return;
        }

        if (!is2FAEnabled?.data?.twoFactorEnabled) {
            toast.error('2FA is not enabled for your account');
            return;
        }

        if (!!customFormErrors?.length) {
            toast.error(customFormErrors[0]?.message ?? 'Please fill all required fields.');
            return;
        }

        setModal({
            isVisible: true,
            title: "Request a refund",
            description: "Are you sure you want to request a refund for this payment? This action cannot be undone.",
            otpRequired: true,
            confirmText: 'Request a refund',
            onConfirm: () => {
                if (isOtpRequired() && !getOtpValue()) {
                    toast.error('Please enter OTP to confirm action.');
                    return;
                }
                requestPaymentRefundMutation.mutate({otp: getOtpValue()});
                hideModal();
            },
            onCancel: () => {
                hideModal();
            },
        })
    }

    const requestPaymentRefundMutation = useMutation({
        mutationKey: 'request_payment_refund', mutationFn: async ({otp}) => {
            console.log(formData)
            const {data} = await apollo.mutate({
                mutation: REQUEST_PAYMENT_REFUND, variables: {
                    paymentUuid: payment.paymentUuid,
                    otp,
                    formData: !!formData ? JSON.stringify(formData) : null
                },
            });
            return data?.requestPaymentRefund;
        }, onSuccess: (data) => {
            if (data?.success) {
                toast.success(data?.message ?? 'Refund request has been sent.');
            } else {
                toast.error(data?.message ?? 'Refund request failed.');
            }
        }
    })

    // conditionally import the refund form
    const renderForm = () => {
        // methaq prod M135728300300999
        // samir dev,prod M862248712438901, M297763427187004
        if (['M135728300300999', 'M862248712438901', 'M297763427187004'].includes(payment?.merchant?.merchantUuid)) {
            return <MethaqRefundForm payment={payment} onFormChange={setFormData}
                                     setCustomFormErrors={setCustomFormErrors}/>;
        }
        return <DefaultRefundForm payment={payment} onFormChange={setFormData}
                                  setCustomFormErrors={setCustomFormErrors}/>;
    }

    return <div className="py-6">
        {renderForm()}
        <Button secondary={false} onClick={handleRefundAction}
                disabled={requestPaymentRefundMutation.isLoading || !payment?.isRefundAutomated}>Request a
            Refund</Button>
    </div>;
}


export default RefundForm;