import React, {useState, useMemo} from 'react';
import TextInput from "../../../../components/TextInput";
import DatePicker from "react-datepicker";
import Button from "../../../../components/Button";

const CURRENT_DAY = `0${new Date().getDate()}`.slice(-2);
const CURRENT_MONTH = `0${new Date().getMonth() + 1}`.slice(-2);
const CURRENT_YEAR = new Date().getFullYear().toString();

function MethaqRefundForm({payment, onFormChange, setCustomFormErrors}) {
    const [quotationNumber, setQuotationNumber] = useState('');
    const [policyNumber, setPolicyNumber] = useState('');
    const [creditNoteNumber, setCreditNoteNumber] = useState('');
    const [ibanNumber, setIbanNumber] = useState('');
    const [cancellationDate, setCancellationDate] = useState({
        day: CURRENT_DAY,
        month: CURRENT_MONTH,
        year: CURRENT_YEAR
    });
    const [insuredName, setInsuredName] = useState('');
    const [endorsementNumber, setEndorsementNumber] = useState('');
    const [beneficiaryName, setBeneficiaryName] = useState('');
    const [brokerCode, setBrokerCode] = useState('');
    const [refundReason, setRefundReason] = useState('');
    const [refundAmount, setRefundAmount] = useState('');

    const validateFields = () => {
        const newErrors = [];
        if (!quotationNumber && !policyNumber) {
            newErrors.push({
                field: "quotationOrPolicyNumber",
                message: "Either Quotation Number or Policy Number is required."
            });
        }
        if (!creditNoteNumber) newErrors.push({field: "creditNoteNumber", message: "Credit Note Number is required."});
        if (!ibanNumber) newErrors.push({field: "ibanNumber", message: "IBAN Number is required."});
        if (!insuredName) newErrors.push({field: "insuredName", message: "Insured Name is required."});
        if (!endorsementNumber) newErrors.push({
            field: "endorsementNumber",
            message: "Endorsement Number is required."
        });
        if (!beneficiaryName) newErrors.push({field: "beneficiaryName", message: "Beneficiary Name is required."});
        if (!brokerCode) newErrors.push({field: "brokerCode", message: "Broker Code is required."});
        if (!refundAmount) newErrors.push({field: "refundAmount", message: "Refund Amount is required."});
        setCustomFormErrors(newErrors);
    };

    const handleFormChange = () => {
        validateFields();

        onFormChange({
            "Refund Amount": refundAmount,
            "Refund Reason": refundReason,

            "Quotation Number": quotationNumber,
            "Policy Number": policyNumber,
            "Credit Note Number": creditNoteNumber,
            "IBAN Number": ibanNumber,
            "Cancellation Date": `${cancellationDate.year}-${cancellationDate.month}-${cancellationDate.day}`,
            "Insured Name": insuredName,
            "Endorsement Number": endorsementNumber,
            "Beneficiary Name (as per IBAN)": beneficiaryName,
            "Broker Code": brokerCode,
        });
    };

    useMemo(() => {
        handleFormChange();
    }, [
        refundAmount,
        refundReason,

        quotationNumber,
        policyNumber,
        creditNoteNumber,
        ibanNumber,
        cancellationDate,
        insuredName,
        endorsementNumber,
        beneficiaryName,
        brokerCode,
    ]);

    return (
        <div className="grid gap-x-6 gap-y-8 grid-cols-12 mb-4">
            <div className="col-span-12 sm:col-span-6 lg:col-span-4">
                <TextInput name="refundAmount" label="Refund Amount" id="refundAmount"
                           type="number"
                           get={refundAmount}
                           set={setRefundAmount} required={true}/>
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-4">
                <TextInput name="refundReason" label="Refund Reason" id="refundReason" type="text"
                           get={refundReason}
                           set={setRefundReason} required={false}/>
            </div>

            <div className="col-span-12 sm:col-span-6 lg:col-span-4">
                <TextInput name="quotationNumber" label="Quotation Number" id="quotationNumber" type="text"
                           get={quotationNumber}
                           set={setQuotationNumber} required={false}/>
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-4">
                <TextInput name="policyNumber" label="Policy Number" id="policyNumber" type="text"
                           get={policyNumber}
                           set={setPolicyNumber} required={false}/>
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-4">
                <TextInput name="creditNoteNumber" label="Credit Note Number" id="creditNoteNumber" type="text"
                           get={creditNoteNumber}
                           set={setCreditNoteNumber} required={true}/>
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-4">
                <TextInput name="ibanNumber" label="IBAN Number" id="ibanNumber" type="text"
                           get={ibanNumber}
                           set={setIbanNumber} required={true}/>
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-4">
                <label htmlFor="cancellationDate" className="block text-sm font-medium text-gray-700">
                    Cancellation Date <span className="text-red-500" aria-label="Required">*</span>
                </label>
                <DatePicker
                    id="cancellationDate"
                    selected={new Date(`${cancellationDate.year}-${cancellationDate.month}-${cancellationDate.day}`)}
                    onChange={(selectedDate) => {
                        setCancellationDate({
                            day: `0${selectedDate.getDate()}`.slice(-2),
                            month: `0${selectedDate.getMonth() + 1}`.slice(-2),
                            year: selectedDate.getFullYear().toString(),
                        });
                    }}
                    // minDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    required
                    wrapperClassName="w-full mt-2"
                />
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-4">
                <TextInput name="insuredName" label="Insured Name" id="insuredName" type="text"
                           get={insuredName}
                           set={setInsuredName} required={true}/>
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-4">
                <TextInput name="endorsementNumber" label="Endorsement Number" id="endorsementNumber" type="text"
                           get={endorsementNumber}
                           set={setEndorsementNumber} required={true}/>
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-4">
                <TextInput name="beneficiaryName" label="Beneficiary Name (as per IBAN)" id="beneficiaryName"
                           type="text"
                           get={beneficiaryName}
                           set={setBeneficiaryName} required={true}/>
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-4">
                <TextInput name="brokerCode" label="Broker Code" id="brokerCode" type="text"
                           get={brokerCode}
                           set={setBrokerCode} required={true}/>
            </div>
        </div>
    );
}

export default MethaqRefundForm;