import React, {useMemo} from 'react';
import DashboardLayout from "../../../layouts/DashboardLayout";
import {useParams} from "react-router-dom";
import {useFetchPaymentQuery} from "../../../utils/queries";
import Loading from "../../../components/Loading";
import RefundForm from "./refund-form";
import PageHeader from "../../../components/PageHeader";

function RefundCreate({paymentData}) {
    const {payment_uuid} = useParams();
    const fetchPaymentQuery = useFetchPaymentQuery(payment_uuid);
    const payment = useMemo(() => fetchPaymentQuery?.isSuccess ? fetchPaymentQuery?.data : null, [fetchPaymentQuery]);

    return (
        <DashboardLayout>
            <PageHeader back={`/merchant/${payment_uuid ? `payments/view/${payment_uuid}` : 'refunds'}`} title="Refund"
                        description="This page provides you with the capability to generate new payment links for seamless transactions. Below, you can also find a list of refunds processed through the system."/>

            {fetchPaymentQuery.isLoading && <Loading/>}
            {fetchPaymentQuery.isError && <div>Error loading payment details.</div>}
            {fetchPaymentQuery.isSuccess && <RefundForm payment={paymentData ?? payment}/>}
        </DashboardLayout>
    );
}

export default RefundCreate;