import {useQuery} from "react-query";
import {apollo} from "../utils/apollo";
import {IS_2FA_ENABLED} from "../utils/graphql";

// Custom hook to fetch the 2FA status
export default function use2FAStatus() {
    return useQuery(
        'is_2fa_enabled',
        async () => {
            const {data} = await apollo.query({
                query: IS_2FA_ENABLED,
                variables: {},
            });
            return data?.is2FAEnabled;
        },
        {
            staleTime: 25000,
            cacheTime: 30000,
            keepPreviousData: false,
        }
    );
}