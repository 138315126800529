import React, {useMemo, useState} from 'react';
import DashboardLayout from "../../../layouts/DashboardLayout";
import PageHeader from "../../../components/PageHeader";
import {useTranslation} from "react-i18next";
import ButtonInternalLink from "../../../components/ButtonInternalLink";
import {useQuery} from "react-query";
import SortIcon from "../../../components/SortIcon";
import StatusBadge from "../../../components/StatusBadge";
import {Link} from "react-router-dom";
import Loading from "../../../components/Loading";
import {apollo} from "../../../utils/apollo";
import {GET_SUB_USER_LIST} from "../../../utils/graphql";
import PaginationFull from "../../../components/PaginationFull";

function SubUser() {
    const {t} = useTranslation();
    const [sort, setSort] = useState({field: 'id', isAscending: false});
    const [page, setPage] = useState(1);
    const [showFilter, setShowFilter] = useState(false);
    const [statusFilter, setStatusFilter] = useState('')
    const tableHeader = [
        {column: 'user_uuid', title: t('user_management.table.user_uuid'), sortable: true},
        {column: 'name', title: t('user_management.table.name'), sortable: true},
        {column: 'role', title: t('user_management.table.role'), sortable: false},
        {column: 'email', title: t('user_management.table.email'), sortable: true},
        {column: 'country', title: t('user_management.table.country'), sortable: false},
        {column: 'verified', title: t('user_management.table.verified'), sortable: true},
        {column: 'active', title: t('user_management.table.active'), sortable: true},
        {column: 'created_at', title: t('user_management.table.date'), sortable: true},
        {column: 'view', title: t('user_management.table.view'), sortable: false, hidden: true},
    ]

    const fetchUsersList = useQuery(
        ['users_list', {page, sort, statusFilter}],
        async () => {
            const {data} = await apollo.query({
                query: GET_SUB_USER_LIST,
                variables: {
                    sortBy: sort.field?.toUpperCase(),
                    sortDirection: sort.isAscending ? "ASC" : "DESC",
                    page,
                    first: 15,
                    filter: {
                        status: statusFilter
                    }
                },
            });
            return data?.getSubUserList;
        },
        {keepPreviousData: false}
    );
    const records = useMemo(() => fetchUsersList?.isSuccess ? fetchUsersList?.data : null, [fetchUsersList]);

    const handleChangeSort = (field) => {
        if (sort.field === field) {
            setSort(prevState => ({...prevState, isAscending: !prevState.isAscending}));
        } else {
            setSort({field: field, isAscending: true});
        }
    };

    return (
        <DashboardLayout>
            <PageHeader title={t('user_management.page_title')} description={t('user_management.page_description')}>
                <div className="flex items-center gap-3">
                    {/*<IconButton onClick={() => setShowFilter(!showFilter)}>*/}
                    {/*    <FunnelIcon className="w-5 h-5 text-blue-500"/>*/}
                    {/*</IconButton>*/}
                    <ButtonInternalLink
                        href="/merchant/sub-users/create">{t('user_management.create_sub_user')}</ButtonInternalLink>
                </div>
            </PageHeader>
            {showFilter && <div className="mt-3 shadow-md bg-white border rounded px-4 py-2">
                filter
            </div>}
            {fetchUsersList.isLoading && <Loading/>}
            {(fetchUsersList.isSuccess && records) && <>
                <div className="flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                <tr>
                                    {tableHeader?.map(item => (<th key={item.column} scope="col"
                                                                   className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
                                        {!item.hidden && <>
                                            {item.sortable ?
                                                <button onClick={() => handleChangeSort(item.column)} type="button"
                                                        className="group inline-flex">
                                                    {item.title}
                                                    <SortIcon sort={sort} field={item.column}/>
                                                </button> : <span>{item.title}</span>}
                                        </>}
                                    </th>))}
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                {records?.data?.map((record) => (
                                    <tr key={record?.userUuid} className="hover:bg-gray-200/60">
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{record?.userUuid}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.name}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.roles?.join(', ')}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.email}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.country?.englishName}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><StatusBadge
                                            status={record?.verified ? 'Yes' : 'No'}/></td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><StatusBadge
                                            status={record?.active ? 'active' : 'inactive'}/></td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.createdAt}</td>
                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-0">
                                            <Link to={`/merchant/sub-users/view/${record?.userUuid}`}
                                                  className="text-blue-500 hover:text-blue-900">View</Link>
                                        </td>
                                    </tr>))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {/*<Pagination paginator={records?.paginatorInfo} get={page} set={setPage}/>*/}
                <PaginationFull paginator={records?.paginatorInfo} get={page} set={setPage}/>
            </>}
        </DashboardLayout>
    );
}

export default SubUser;

