import React, {useState, useMemo} from 'react';
import TextInput from "../../../../components/TextInput";
import DatePicker from "react-datepicker";
import Button from "../../../../components/Button";


const CURRENT_DAY = `0${new Date().getDate()}`.slice(-2);
const CURRENT_MONTH = `0${new Date().getMonth() + 1}`.slice(-2);
const CURRENT_YEAR = new Date().getFullYear().toString();

function DefaultRefundForm({payment, onFormChange, setCustomFormErrors}) {
    const [refundReason, setRefundReason] = useState('');
    const [refundAmount, setRefundAmount] = useState(payment.amount || '');

    const validateFields = () => {
        const newErrors = [];
        if (!refundAmount) newErrors.push({field: "refundAmount", message: "Refund Amount is required."});
        setCustomFormErrors(newErrors);
    };
    const handleFormChange = () => {
                validateFields();

        onFormChange({
            "Refund Amount": refundAmount,
            "Refund Reason": refundReason,
        });
    };

    useMemo(() => {
        handleFormChange();
    }, [
        refundAmount,
        refundReason,
    ]);

    return (
        <div className="grid gap-x-6 gap-y-8 grid-cols-12 mb-4">
            <div className="col-span-12 sm:col-span-6 lg:col-span-4">
                <TextInput name="refundAmount" label="Refund Amount as per Credit Note" id="refundAmount"
                           type="number"
                           get={refundAmount}
                           set={setRefundAmount} required={true}/>
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-4">
                <TextInput name="refundReason" label="Refund Reason" id="refundReason" type="text"
                           get={refundReason}
                           set={setRefundReason} required={false}/>
            </div>
        </div>
    );
}

export default DefaultRefundForm;