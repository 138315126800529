import React from 'react';

function TextInput({label, id, name, type, required, get, set, readOnly, disabled, format}) {
    return (
        <div>
            <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
                {label} {required && <span className="text-red-500" aria-label="Required">*</span>}
            </label>
            <div className="mt-2">
                <input
                    id={id}
                    name={name}
                    type={type || 'text'}
                    value={get}
                    disabled={disabled}
                    format={format || null}
                    onChange={e => set(e.target.value)}
                    required={required || false}
                    readOnly={readOnly || false}
                    min={0}
                    className="block disabled:opacity-60 disabled:bg-gray-200 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                />
            </div>
        </div>
    );
}

export default TextInput;