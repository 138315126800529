import React, {useState} from 'react';
import DashboardLayout from "../../../layouts/DashboardLayout";
import PageHeader from "../../../components/PageHeader";
import {FunnelIcon} from "@heroicons/react/24/outline";
import StatusBadge from "../../../components/StatusBadge";
import {useTranslation} from "react-i18next";
import {useMutation, useQuery, useQueryClient} from "react-query";
import SortIcon from "../../../components/SortIcon";
import SelectInput from "../../../components/SelectInput";
import Loading from "../../../components/Loading";
import {apollo} from "../../../utils/apollo";
import {GET_REFUND_LIST, MAKE_REFUND_DECISION} from "../../../utils/graphql";
import IconButton from "../../../components/IconButton";
import PaginationFull from "../../../components/PaginationFull";
import Button from "../../../components/Button";
import {toast} from "react-toastify";
import ButtonLink from "../../../components/ButtonLink";
import useModalStore from "../../../store/modal";
import useAuthStore from "../../../store/auth";
import use2FAStatus from "../../../hooks/use2FAStatus";
import RefundReportGenerateDialog from "../../../components/RefundReportGenerateDialog";
import ButtonInternalLink from "../../../components/ButtonInternalLink";

function Refund() {
    const {t} = useTranslation();
    const [sort, setSort] = useState({field: 'id', isAscending: false});
    const [page, setPage] = useState(1);
    const [showFilter, setShowFilter] = useState(false);
    const [statusFilter, setStatusFilter] = useState('')
    const {setModal, clearModal, hideModal} = useModalStore();
    const {user} = useAuthStore();
    const queryClient = useQueryClient()
    const [showRefundReportDialog, setShowRefundReportDialog] = useState(false);
    const [refundReportFilter, setRefundReportFilter] = useState({status: '', test: '', paymentUuid: '', supplier: ''})

    // const {data: records, isLoading, error} = useQuery('payment_links', getPaymentLinks)

    const tableHeader = [
        {column: 'refund_uuid', title: t('refund.table.refund_uuid'), sortable: false},
        {column: 'payment_uuid', title: t('refund.table.payment_uuid'), sortable: false},
        // {column: 'merchant_id', title: t('refund.table.merchant_id'), sortable: false},
        {column: 'user', title: t('refund.table.user'), sortable: false},
        // {column: 'supplier', title: t('refund.table.supplier'), sortable: false},
        // {column: 'authority_id', title: t('refund.table.authority_id'), sortable: false},
        // {column: 'amount', title: t('refund.table.amount'), sortable: false},
        // {column: 'settlement_amount', title: t('refund.table.settlement_amount'), sortable: true},
        // {column: 'settlement_date', title: t('refund.table.settlement_date'), sortable: true},
        // {column: 'receipt', title: t('refund.table.receipt'), sortable: false},
        {column: 'requested_at', title: t('refund.table.requested_at'), sortable: false},
        {column: 'refund_reference', title: t('refund.table.refund_reference'), sortable: false},
        {column: 'status', title: t('refund.table.status'), sortable: false},
        // {column: 'status', title: t('refund.table.payment'), sortable: false},
        {column: 'make_decision', title: t('refund.table.make_decision'), sortable: false},
        // {column: 'created_at', title: t('refund.table.created_at'), sortable: true},
        // {column: 'actions', title: t('refund.table.actions'), sortable: false, hidden: true},
    ]

    const fetchRefunds = useQuery(
        ['refund_list', {page, sort, statusFilter}],
        async () => {
            const {data} = await apollo.query({
                query: GET_REFUND_LIST,
                variables: {
                    sortBy: sort.field?.toUpperCase(),
                    sortDirection: sort.isAscending ? "ASC" : "DESC",
                    page,
                    first: 15,
                    filter: {
                        status: statusFilter
                    }
                },
            });
            return data?.getRefundList;
        },
        {keepPreviousData: false}
    );

    const records = fetchRefunds?.data || null;

    const handleChangeSort = (field) => {
        if (sort.field === field) {
            setSort(prevState => ({...prevState, isAscending: !prevState.isAscending}));
        } else {
            setSort({field: field, isAscending: true});
        }
    };

    // todo: make mutation for makeRefundDecision

    // const handleRefundDecision = async (uuid, decision) => {
    //     // decision: Approve External, Approve, Reject
    //     let title = "";
    //     let description = "";
    //     let refundDecision = "";
    //     let confirmText = "";
    //     switch (decision) {
    //         case "approve_external":
    //             title = "Approve external refund request";
    //             description = "Are you sure you want to approve this refund request? This action cannot be undone.";
    //             refundDecision = "approve_external";
    //             confirmText = "Approve External";
    //             break;
    //         case "approve":
    //             title = "Approve refund request";
    //             description = "Are you sure you want to approve this refund request? This action cannot be undone.";
    //             refundDecision = "approve";
    //             confirmText = "Approve";
    //             break;
    //         case "reject":
    //             title = "Reject refund request";
    //             description = "Are you sure you want to reject this refund request? This action cannot be undone.";
    //             refundDecision = "reject";
    //             confirmText = "Reject";
    //             break;
    //         default:
    //             title = "Invalid refund request decision";
    //             description = "The selected refund decision is not recognized. Please choose a valid option.";
    //             refundDecision = "unknown";
    //             confirmText = "Unknown";
    //     }
    //
    //     setModal({
    //         isVisible: true,
    //         title: title,
    //         description: description,
    //         otpRequired: false,
    //         confirmText: confirmText,
    //         onConfirm: () => {
    //             makeRefundDecisionMutation.mutate({
    //                 refundUuid: uuid,
    //                 refundDecision: refundDecision,
    //             })
    //             hideModal();
    //         },
    //         onCancel: () => {
    //             hideModal();
    //         },
    //     })
    // }

    return (
        <DashboardLayout>
            <PageHeader title={t('refund.page_title')} description={t('refund.page_description')}>
                <div className="flex items-center gap-3">
                    <IconButton onClick={() => setShowFilter(!showFilter)}>
                        <FunnelIcon className="w-5 h-5 text-blue-500"/>
                    </IconButton>
                    <Button
                        secondary={true}
                        onClick={() => setShowRefundReportDialog(true)}>{t('refund.download_reports')}</Button>
                </div>
            </PageHeader>
            <RefundReportGenerateDialog active={showRefundReportDialog} toggle={setShowRefundReportDialog}
                                        filter={refundReportFilter}/>
            <div className="my-4 text-sm text-gray-500">
                Note: Refund approvals can only be performed by authorized users as defined by administrators. For
                changes to the authorized members, please contact your administrator.
            </div>
            {showFilter && <div className="my-4 shadow-md bg-white border rounded-lg px-4 py-4 grid grid-cols-12">
                <div className="col-span-3">
                    <SelectInput name="status-filter" label="Filter By Status" id="status-filter" get={statusFilter}
                                 set={setStatusFilter}>
                        <option value="">All</option>
                        <option value="pending">Pending</option>
                        <option value="approved">Approved</option>
                        <option value="rejected">Rejected</option>
                        <option value="refunded">Refunded</option>
                    </SelectInput>
                </div>
            </div>}
            {fetchRefunds.isLoading && <Loading/>}
            {(fetchRefunds.isSuccess && records) && <>
                <div className="flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                <tr>
                                    {tableHeader?.map(item => (<th key={item.column} scope="col"
                                                                   className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
                                        {!item.hidden && <>
                                            {item.sortable ?
                                                <button onClick={() => handleChangeSort(item.column)} type="button"
                                                        className="group inline-flex">
                                                    {item.title}
                                                    <SortIcon sort={sort} field={item.column}/>
                                                </button> : <span>{item.title}</span>}
                                        </>}
                                    </th>))}
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                {records?.data?.map((record) => (
                                    <tr key={record?.refundUuid} className="hover:bg-gray-200/60">
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{record?.refundUuid}</td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{record?.payment?.paymentUuid}</td>
                                        {/*<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.merchant?.merchantName}</td>*/}
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.user?.name}</td>
                                        {/*<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.supplier?.supplierName || 'N/A'}</td>*/}
                                        {/*<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.authority?.name || 'N/A'}</td>*/}
                                        {/*<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.payment?.amount} {record?.currency?.symbol}</td>*/}
                                        <td
                                            className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.refundReference}</td><td
                                            className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.createdAt}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <StatusBadge status={record?.status}/>
                                        </td>
                                        {/*<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">*/}
                                        {/*    <div className="flex gap-1">*/}
                                        {/*        <ButtonInternalLink target="_blank" secondary={true}*/}
                                        {/*                    href={`/merchant/payments/view/${record?.payment?.paymentUuid}`}>*/}
                                        {/*            {t('refund.view')}*/}
                                        {/*        </ButtonInternalLink>*/}
                                        {/*    </div>*/}
                                        {/*</td>*/}
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <div className="flex gap-1">
                                                <ButtonInternalLink secondary={false}
                                                                    href={`/merchant/refunds/view/${record?.refundUuid}`}>
                                                    {record?.status === 'pending' ? t('refund.table.make_decision') : t('refund.table.view_details')}
                                                </ButtonInternalLink>
                                            </div>
                                        </td>
                                    </tr>))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <PaginationFull paginator={records?.paginatorInfo} get={page} set={setPage}/>
            </>}
        </DashboardLayout>
    );
}

export default Refund;

