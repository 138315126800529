import React, {useState} from 'react';
import DashboardLayout from "../../../layouts/DashboardLayout";
import PageHeader from "../../../components/PageHeader";
import Pagination from "../../../components/Pagination";
import {EyeIcon, FunnelIcon} from "@heroicons/react/24/outline";
import StatusBadge from "../../../components/StatusBadge";
import {useTranslation} from "react-i18next";
import {useMutation, useQuery} from "react-query";
import {Link} from "react-router-dom";
import SortIcon from "../../../components/SortIcon";
import SelectInput from "../../../components/SelectInput";
import Loading from "../../../components/Loading";
import {apollo} from "../../../utils/apollo";
import {
    GET_MERCHANT_SUPPLIERS_LIST,
    GET_SETTLEMENT_LIST,
    GET_SETTLEMENT_REPORT_URL,
    REQUEST_SETTLEMENT
} from "../../../utils/graphql";
import IconButton from "../../../components/IconButton";
import ButtonInternalLink from "../../../components/ButtonInternalLink";
import PaginationFull from "../../../components/PaginationFull";
import Button from "../../../components/Button";
import {toast} from "react-toastify";
import {clearCachedKey} from "../../../utils/helpers";

function Settlement() {
    const {t} = useTranslation();
    const [sort, setSort] = useState({field: 'id', isAscending: false});
    const [supplier, setSupplier] = useState(null);
    const [page, setPage] = useState(1);
    const [showFilter, setShowFilter] = useState(false);
    const [statusFilter, setStatusFilter] = useState('')
    // const {data: records, isLoading, error} = useQuery('payment_links', getPaymentLinks)

    const tableHeader = [
        {column: 'settlement_uuid', title: t('settlement.table.settlement_uuid'), sortable: true},
        // {column: 'merchant_id', title: t('settlement.table.merchant_id'), sortable: false},
        // {column: 'user_id', title: t('settlement.table.user_id'), sortable: false},
        // {column: 'authority_id', title: t('settlement.table.authority_id'), sortable: false},
        {column: 'supplier', title: t('settlement.table.supplier'), sortable: false},
        {column: 'request_amount', title: t('settlement.table.request_amount'), sortable: true},
        {column: 'settlement_amount', title: t('settlement.table.settlement_amount'), sortable: true},
        {column: 'settlement_date', title: t('settlement.table.settlement_date'), sortable: true},
        {column: 'status', title: t('settlement.table.status'), sortable: true},
        {column: 'receipt', title: t('settlement.table.receipt'), sortable: false},
        {column: 'report', title: t('settlement.table.report'), sortable: false},
        {column: 'paymentReference', title: t('settlement.table.paymentReference'), sortable: false},
        // {column: 'created_at', title: t('settlement.table.created_at'), sortable: true},
        {column: 'actions', title: t('settlement.table.actions'), sortable: false, hidden: true},
    ]

    const requestSettlementMutation = useMutation({
        mutationFn: async () => {
            const {data} = await apollo.mutate({
                mutation: REQUEST_SETTLEMENT,
                variables: {
                    supplier: supplier,
                },
            });
            return data?.requestSettlement;
        },
        onSuccess: (data) => {
            if (data && data.success) {
                toast.success(data.message);
            } else {
                toast.error(data.message)
            }
            clearCachedKey(['settlement_list'])
        },
    })

    const getSettlementReportUrlMutation = useMutation({
        mutationFn: async (variables) => {
            const {data} = await apollo.mutate({
                mutation: GET_SETTLEMENT_REPORT_URL,
                variables: {
                    settlementUuid: variables?.settlementUuid
                },
            });
            if (!!data?.getSettlementReportUrl) {
                window.location.href = data?.getSettlementReportUrl;
            }
        }
    })

    const fetchSettlements = useQuery(
        ['settlement_list', {page, sort, statusFilter}],
        async () => {
            const {data} = await apollo.query({
                query: GET_SETTLEMENT_LIST,
                variables: {
                    sortBy: sort.field?.toUpperCase(),
                    sortDirection: sort.isAscending ? "ASC" : "DESC",
                    page,
                    first: 15,
                    filter: {
                        status: statusFilter
                    }
                },
            });
            return data?.getSettlementList;
        },
        {keepPreviousData: false}
    );
    const fetchMerchantSuppliersList = useQuery({
        queryKey: 'merchant_suppliers_list',
        queryFn: async () => {
            const {data} = await apollo.query({
                query: GET_MERCHANT_SUPPLIERS_LIST,
                variables: {},
            });
            return data?.getMerchantSuppliersList;
        },
        keepPreviousData: false
    });

    const records = fetchSettlements?.data || null;
    const suppliersList = fetchMerchantSuppliersList?.isSuccess ? fetchMerchantSuppliersList?.data : null;

    const handleChangeSort = (field) => {
        if (sort.field === field) {
            setSort(prevState => ({...prevState, isAscending: !prevState.isAscending}));
        } else {
            setSort({field: field, isAscending: true});
        }
    };

    const handleRequestSettlement = () => {
        if (requestSettlementMutation.isLoading) return;
        requestSettlementMutation.mutate();
    }

    return (
        <DashboardLayout>
            <PageHeader title={t('settlement.page_title')} description={t('settlement.page_description')}>
                <div className="flex items-center gap-3">
                    <IconButton onClick={() => setShowFilter(!showFilter)}>
                        <FunnelIcon className="w-5 h-5 text-blue-500"/>
                    </IconButton>
                </div>
            </PageHeader>
            <div className="flex justify-end items-end gap-3 my-3">
                <SelectInput name="supplier-filter" label="" id="supplier-filter" get={supplier}
                             className="w-full md:w-64"
                             set={v => setSupplier(v)}>
                    <option value="">Merchant</option>
                    {suppliersList?.map(supplier => <option key={supplier.supplierUuid}
                                                            value={supplier.supplierUuid}>Vendor: {supplier.supplierName}</option>)}
                </SelectInput>
                <Button className="shrink-0" disabled={requestSettlementMutation.isLoading}
                        onClick={handleRequestSettlement}>{t('settlement.request_settlement')}</Button>
            </div>
            {showFilter && <div className="my-4 shadow-md bg-white border rounded-lg px-4 py-4 grid grid-cols-12">
                <div className="col-span-3">
                    <SelectInput name="status-filter" label="Filter By Status" id="status-filter" get={statusFilter}
                                 set={setStatusFilter}>
                        <option value="">All</option>
                        <option value="pending">Pending</option>
                        <option value="approved">Approved</option>
                        <option value="rejected">Rejected</option>
                        <option value="under process">Under Process</option>
                        <option value="completed">Completed</option>
                    </SelectInput>
                </div>
            </div>}
            {fetchSettlements.isLoading && <Loading/>}
            {(fetchSettlements.isSuccess && records) && <>
                <div className="flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                <tr>
                                    {tableHeader?.map(item => (<th key={item.column} scope="col"
                                                                   className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
                                        {!item.hidden && <>
                                            {item.sortable ?
                                                <button onClick={() => handleChangeSort(item.column)} type="button"
                                                        className="group inline-flex">
                                                    {item.title}
                                                    <SortIcon sort={sort} field={item.column}/>
                                                </button> : <span>{item.title}</span>}
                                        </>}
                                    </th>))}
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                {records?.data?.map((record) => (
                                    <tr key={record?.settlementUuid} className="hover:bg-gray-200/60">
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{record?.settlementUuid}</td>
                                        {/*<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.merchant?.merchantName}</td>*/}
                                        {/*<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.user?.name}</td>*/}
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.supplier?.supplierName || 'N/A'}</td>
                                        {/*<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.authority?.name || 'N/A'}</td>*/}
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.requestAmount} {record?.currency?.symbol}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.settlementAmount} {record?.settlementAmount ? record?.currency?.symbol : 'N/A'}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.settlementDate}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <StatusBadge status={record?.status}/>
                                        </td>
                                        {/*<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.createdAt}</td>*/}
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <Button onClick={() => {
                                                window.location.href = `${process.env.REACT_APP_PAYD_API_BASE_URL}/${record?.receipt}`;
                                            }} disabled={!record?.receipt}>
                                                {t('settlement.download')}
                                            </Button>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <Button onClick={() => {
                                                getSettlementReportUrlMutation.mutate({settlementUuid: record?.settlementUuid})
                                            }}
                                                    disabled={getSettlementReportUrlMutation.isLoading}>
                                                {t('settlement.download')}
                                            </Button>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <div className="line-clamp-2 text-pretty">{record?.paymentReference}</div>
                                        </td>
                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-0 flex gap-2 items-center">
                                            <Link to={`/merchant/settlements/view/${record?.settlementUuid}`}
                                                  className="text-blue-500 hover:text-blue-900 select-none p-1">
                                                <EyeIcon className="w-5 h-5"/>
                                            </Link>
                                        </td>
                                    </tr>))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {/*<Pagination paginator={records?.paginatorInfo} get={page} set={setPage}/>*/}
                <PaginationFull paginator={records?.paginatorInfo} get={page} set={setPage}/>
            </>}
        </DashboardLayout>
    );
}

export default Settlement;

