import {useMutation} from "react-query";
import {MAKE_REFUND_DECISION} from "./graphql";
import {apollo} from "./apollo";
import {toast} from "react-toastify";
import {clearCachedKey} from "./helpers";

export const useMakeRefundDecisionMutation = () => {
    return useMutation({
        mutationKey: 'make_refund_decision',
        mutationFn: async ({refundUuid, refundDecision}) => {
            const {data} = await apollo.mutate({
                mutation: MAKE_REFUND_DECISION,
                variables: {
                    refundUuid: refundUuid,
                    refundDecision: refundDecision,
                },
            });
            return data?.makeRefundDecision;
        },
        onSuccess: (data) => {
            if (data?.success && data?.message) {
                toast.success(data.message);
            } else if (!data?.success && data?.message) {
                toast.error(data.message);
            }
        },
        onSettled: () => {
            clearCachedKey(['refund_view'])
        }
    });
};
