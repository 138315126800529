import {gql} from "@apollo/client";

export const GET_PAYMENT_LIST = gql`
    query GetPaymentList(
        $sortBy: PaymentListSortBy
        $sortDirection: SortDirection
        $page: Int
        $first: Int! = 10
        $filter: PaymentListFilterInput
    ) {
        getPaymentList(
            sortBy: $sortBy
            sortDirection: $sortDirection
            page: $page
            first: $first
            filter: $filter
        ) {
            data {
                paymentUuid
                name
                email
                phone
                paidAt
                fromApi
                currency {
                    currencyUuid
                    symbol
                    name
                }
                user {
                    email
                }
                supplier {
                    supplierUuid
                    supplierName
                }
                authCode
                cardNumber
                gatewayReferenceNumber
                customerReferenceNumber
                amount
                remarks
                status
                test
                antiFraud
                createdAt
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
            }
        }
    }
`;

export const GET_PAYMENT_TRANSACTIONS = gql`
    query getPaymentTransactions($paymentUuid: String!) {
        getPaymentTransactions(paymentUuid: $paymentUuid) {
            merchantReference
            transactionId
            authCode
            cardNumber
            # gatewayType
            status
            description
            # log
            createdAt
        }
    }
`;

export const GET_DOCS_V1_LIST = gql`
    query GetDocsV1List(
        $page: Int
        $first: Int! = 50
    ) {
        getDocsV1List(
            page: $page
            first: $first
        ) {
            data {
                id
                name
                slug
                html
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
            }
        }
    }
`;

export const GET_PAYMENT_SUMMARY_LIST = gql`
    query GetPaymentSummaryList(
        $month: String
        $year: String
        $supplier: String
    ) {
        getPaymentSummaryList(
            month: $month
            year: $year
            supplier: $supplier
        ){
            date
            pendingCount
            completedCount
            failedCount
            expiredCount
            refundedCount
            canceledCount
            totalCount
            receivedAmount
        }
    }
`;

export const GET_PAYMENT_DETAILS = gql`
    query GetPaymentDetails(
        $paymentUuid: String!
    ) {
        getPaymentDetails(
            paymentUuid: $paymentUuid
        ) {
            paymentUuid
            name
            email
            phone
            amount
            address
            city
            zipCode
            country
            products {
                id
                title
                details
                amount
                vat
                quantity
                total
            }
            supplier {
                supplierUuid
                supplierName
            }
            callbackUrl
            test
            antiFraud
            status
            splitPayment
            authCode
            cardNumber
            gatewayReferenceNumber
            customerReferenceNumber
            remarks
            paidAt
            refundedAt
            createdAt
            expiresAt
            merchant {
                merchantUuid
                merchantName
                merchantAddress
                merchantPhone
                merchantEmail
                status
                active
            }
            currency {
                currencyUuid
                symbol
                name
            }
            settlement {
                settlementUuid
            }
            gatewayCode
            isRefundAutomated
        }
    }
`;

export const GET_REFUND_DETAILS = gql`
    query GetRefundDetails(
        $refundUuid: String!
    ) {
        getRefundDetails(
            refundUuid: $refundUuid
        ) {
            refundUuid
            merchant {
                merchantName
            }
            user {
                name
            }
            currency {
                currencyUuid
                symbol
                name
            }
            payment {
                paymentUuid
                amount
                status
            }
            supplier {
                supplierUuid
                supplierName
            }
            status
            amount
            refundedAmount
            refundReference
            reason
            formData
            createdAt
            refundedAt
        }
    }
`;

export const GET_CUSTOMER_LIST = gql`
    query GetCustomerList(
        $page: Int
        $first: Int! = 100
    ) {
        getCustomerList (
            page: $page
            first: $first
        ){
            data {
                customerUuid
                name
                email
                phone
                address
                city
                zipCode
                country
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
            }
        }
    }
`;

export const GET_SETTLEMENT_LIST = gql`
    query GetSettlementList(
        $sortBy: SettlementListSortBy
        $sortDirection: SortDirection
        $page: Int
        $first: Int! = 10
        $filter: SettlementListFilterInput
    ) {
        getSettlementList(
            sortBy: $sortBy
            sortDirection: $sortDirection
            page: $page
            first: $first
            filter: $filter
        ) {
            data {
                settlementUuid
                merchant {
                    merchantName
                }
                user {
                    name
                }
                currency {
                    currencyUuid
                    symbol
                    name
                }
                authority {
                    name
                }
                supplier {
                    supplierUuid
                    supplierName
                }
                requestAmount
                settlementAmount
                settlementDate
                paymentReference
                receipt
                status
                remarks
                createdAt
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
            }
        }
    }
`;

export const GET_REFUND_LIST = gql`
    query GetRefundList(
        $page: Int
        $first: Int! = 10
        $filter: RefundListFilterInput
    ) {
        getRefundList(
            page: $page
            first: $first
            filter: $filter
        ) {
            data {
                refundUuid
                merchant {
                    merchantName
                }
                user {
                    name
                }
                currency {
                    currencyUuid
                    symbol
                    name
                }
                payment {
                    paymentUuid
                    amount
                    status
                }
                supplier {
                    supplierUuid
                    supplierName
                }
                status
                amount
                refundedAmount
                refundReference
                reason
                createdAt
                refundedAt
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
            }
        }
    }
`;

export const GET_SUB_USER_LIST = gql`
    query GetSubUserList(
        $sortBy: SubUserListSortBy
        $sortDirection: SortDirection
        $page: Int
        $first: Int! = 10
        $filter: SubUserListFilterInput
    ) {
        getSubUserList(
            sortBy: $sortBy
            sortDirection: $sortDirection
            page: $page
            first: $first
            filter: $filter
        ) {
            data {
                userUuid
                name
                email
                country {
                    englishName
                }
                verified
                active
                createdAt
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
            }
        }
    }
`;

export const GET_PAYMENT_GATEWAY_LIST = gql`
    query GetPaymentGatewayList(
        $sortBy: PaymentGatewayListSortBy
        $sortDirection: SortDirection
        $page: Int
        $first: Int! = 10
        $filter: PaymentGatewayListFilterInput
    ) {
        getPaymentGatewayList(
            sortBy: $sortBy
            sortDirection: $sortDirection
            page: $page
            first: $first
            filter: $filter
        ) {
            data {
                paymentGatewayUuid
                code
                name
                active
                createdAt
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
            }
        }
    }
`;

export const GET_SETTLEMENT = gql`
    query GetSettlement($settlement_uuid: String!) {
        getSettlement(
            settlement_uuid: $settlement_uuid
        ) {
            settlementUuid
            merchant {
                merchantName
            }
            user {
                name
            }
            currency {
                currencyUuid
                symbol
                name
            }
            authority {
                name
            }
            supplier {
                supplierUuid
                supplierName
            }
            requestAmount
            settlementAmount
            settlementDate
            paymentReference
            receipt
            status
            remarks
            createdAt
        }
    }
`;

export const REGISTER = gql`
    mutation Register($input: RegisterFormInput!) {
        register(
            input: $input
        )
    }
`;


export const ME = gql`
    mutation Me {
        me{
            success
            message
            user {
                userUuid
                name
                email
                active
            }
            merchant {
                merchantUuid
                country
                name
                address
                phone
                email
                #status
                active
                currency
            }
            roles
            permissions
        }
    }
`;

export const LOGIN = gql`
    mutation Login($input: LoginFormInput!) {
        login(
            input: $input
        ) {
            success
            message
            token
            user {
                userUuid
                name
                email
                active
            }
            merchant {
                merchantUuid
                country
                name
                address
                phone
                email
                #status
                active
                currency
            }
            roles
            permissions
        }
    }
`;

export const REQUEST_PASSWORD_RESET = gql`
    mutation RequestPasswordReset($input: RequestPasswordResetFormInput!) {
        requestPasswordReset(
            input: $input
        ) {
            success
            message
        }
    }
`;


export const RESET_PASSWORD = gql`
    mutation ResetPassword($input: ResetPasswordFormInput!) {
        resetPassword(
            input: $input
        ) {
            success
            message
        }
    }
`;

export const CREATE_PAYMENT = gql`
    mutation CreatePayment($input: CreatePaymentInput!) {
        createPayment(
            input: $input
        )
    }
`;

export const CREATE_SUB_VENDOR = gql`
    mutation CreateSubVendor($input: CreateSubVendorInput!) {
        createSubVendor(
            input: $input
        )
    }
`;

export const CREATE_BULK_PAYMENT = gql`
    mutation CreateBulkPayment($input: CreateBulkPaymentInput!) {
        createBulkPayment(
            input: $input
        ) {
            success
            message
            errorLines
        }
    }
`;

export const CANCEL_PAYMENT = gql`
    mutation CancelPayment($paymentUuid: String!) {
        cancelPayment(
            paymentUuid: $paymentUuid
        )
    }
`;

export const REQUEST_PAYMENT_REFUND = gql`
    mutation RequestPaymentRefund($paymentUuid: String!, $otp: String, $formData: String) {
        requestPaymentRefund(
            paymentUuid: $paymentUuid
            otp: $otp
            formData: $formData
        ) {
            success
            message
        }
    }
`;

export const MAKE_REFUND_DECISION = gql`
    mutation MakeRefundDecision($refundUuid: String!, $refundDecision: String!) {
        makeRefundDecision(
            refundUuid: $refundUuid
            refundDecision: $refundDecision
        ) {
            success
            message
        }
    }
`;

export const SET_TEST_MODE = gql`
    mutation SetTestMode($enable: Boolean!) {
        setTestMode(
            enable: $enable
        )
    }
`;

export const ACTIVATE_2FA = gql`
    mutation Activate2FA($enable: Boolean!, $otp: String) {
        activate2FA(
            enable: $enable
            otp: $otp
        ) {
            success
            message
            secret
            qrCodeUrl
            recoveryCodes
        }
    }
`;

export const VERIFY_2FA = gql`
    mutation Verify2FA($otp: String!) {
        verify2FA(
            otp: $otp
        ) {
            success
            message
        }
    }
`;

export const GET_TEST_MODE = gql`
    query GetTestMode {
        getTestMode
    }
`;

export const IS_2FA_ENABLED = gql`
    query Is2FAEnabled {
        is2FAEnabled {
            twoFactorEnabled
            validatedOtpRecently
        }
    }
`;

export const CREATE_DIRECT_PAYMENT_LINK = gql`
    mutation CreateDirectPaymentLink($input: CreateDirectPaymentLinkInput!) {
        createDirectPaymentLink(
            input: $input
        ) {
            method
            url
            body
        }
    }
`;

export const GET_TEMPORARY_RECEIPT_LINK = gql`
    mutation GetTemporaryReceiptLink($input: GetTemporaryReceiptLinkInput!) {
        getTemporaryReceiptLink(
            input: $input
        )
    }
`;

export const SEND_RECEIPT_TO_EMAIL = gql`
    mutation SendReceiptToEmail($input: SendReceiptToEmailInput!) {
        sendReceiptToEmail(
            input: $input
        )
    }
`;

export const GET_REGISTRATION_FORM_FIELDS = gql`
    query GetRegistrationForm($country: String) {
        getRegistrationForm (country: $country) {
            id
            countryId
            fieldName
            displayName
            fieldType
            displayOnSection
            valueExpirable
            template
            required
            active
        }
    }
`;

export const GET_PAYMENT_STATUS_COUNT = gql`
    query GetPaymentStatusCount {
        getPaymentStatusCount {
            status
            count
        }
    }
`;

export const GET_MERCHANT_REGISTRATION_FIELDS = gql`
    query GetMerchantRegistrationFields($country: String) {
        getMerchantRegistrationFields {
            id
            fieldValue
            expiresAt
            validated
            fieldDefinition {
                id
                fieldName
            }
        }
        getRegistrationForm (country: $country) {
            id
            countryId
            fieldName
            displayName
            fieldType
            displayOnSection
            valueExpirable
            characterLimit
            template
            format
            required
            active
        }
    }
`;

// export const GET_EDITABLE_REGISTRATION_FORM_FIELDS = gql`
//     query GetEditableRegistrationForm($country: String) {
//         getEditableRegistrationForm {
//             id
//             countryId
//             fieldName
//             displayName
//             fieldType
//             displayOnSection
//             valueExpirable
//             required
//             active
//         }
//     }
// `;

export const GET_INVOICE = gql`
    query GetInvoice($uuid: String) {
        getInvoice (uuid: $uuid) {
            ...on Payment {
                paymentUuid
                merchant {
                    merchantName
                    merchantPhone
                    merchantEmail
                    merchantAddress
                    status
                    active
                }
                currency{
                    currencyUuid
                    symbol
                    name
                }
                name
                phone
                email
                address
                city
                zipCode
                country
                antiFraud
                amount
                callbackUrlAfterTransaction
                products {
                    id
                    title
                    details
                    amount
                    vat
                    quantity
                    total
                }
                supplier {
                    supplierUuid
                    supplierName
                    businessName
                    email
                    phone
                    displaySupplierDetails
                }
                test
                status
                gatewayReferenceNumber
                customerReferenceNumber
                displayReferenceNumber
                authCode
                cardNumber
                remarks
                paidAt
                refundedAt
                createdAt
                splitPayment
                gatewayCode
                lastUsedIdentifier
                latestTransaction {
                    description
                }
            }
        }
    }
`;


export const GET_REGISTRATION_COUNTRIES = gql`
    query GetRegistrationCountries {
        getRegistrationCountries {
            id
            alpha2Code
            alpha3Code
            phoneCode
            englishName
            arabicName
            active
        }
    }
`;

export const GET_MERCHANT_STATISTICS = gql`
    query GetMerchantStatistics {
        getMerchantStatistics {
            id
            name
            stat
            prefix
            suffix
            valueType
            icon
            change
            changeType
        }
    }
`;

export const GET_MERCHANT_SUPPLIERS_STATISTICS = gql`
    query GetMerchantSuppliersStatistics {
        getMerchantSuppliersStatistics {
            id
            name
            value
        }
    }
`;

export const GET_MERCHANT_SUPPLIERS_LIST = gql`
    query GetMerchantSuppliersList {
        getMerchantSuppliersList {
            supplierUuid
            supplierName
        }
    }
`;

export const GET_SUPPLIER_LIST = gql`
    query GetSupplierList {
        getSupplierList {
            data {
                supplierUuid
                supplierName
                businessName
                email
                phone
                displaySupplierDetails
                active
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
            }
        }
    }
`;

export const GET_PAYMENT_REPORT_URL_WITH_DATE = gql`
    mutation GetPaymentReportUrl($input: PaymentReportUrlInput!) {
        getPaymentReportUrl(
            input: $input
        )
    }
`;

export const UPDATE_REGISTRATION = gql`
    mutation UpdateRegistration($input: UpdateRegistrationFormInput!) {
        updateRegistration(
            input: $input
        ){
            success
            message
        }
    }
`;

export const REQUEST_SETTLEMENT = gql`
    mutation RequestSettlement($supplier: String) {
        requestSettlement (supplier: $supplier){
            success
            status
            message
        }
    }
`;

export const GET_SETTLEMENT_REPORT_URL = gql`
    mutation GetSettlementReportUrl($settlementUuid: String!) {
        getSettlementReportUrl(settlementUuid: $settlementUuid)
    }
`;

export const GET_SECRET_KEYS = gql`
    query GetSecretKeys {
        getSecretKeys {
            data {
                apiKey
                testMode
                createdAt
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
            }
        }
    }
`;