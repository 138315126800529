import React, {useState} from 'react';
import DashboardLayout from "../../../layouts/DashboardLayout";
import MutationStatusMessage from "../../../components/MutationStatusMessage";
import PageHeader from "../../../components/PageHeader";
import TextInput from "../../../components/TextInput";
import {useMutation, useQueryClient} from "react-query";
import {apollo} from "../../../utils/apollo";
import {useNavigate} from "react-router-dom";
import {CREATE_SUB_VENDOR} from "../../../utils/graphql";
import Toggle from "../../../components/Toggle";
import Button from "../../../components/Button";
import {toast} from "react-toastify";

function SubVendorCreate() {
    const [name, setName] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [sharePercentage, setSharePercentage] = useState('20');
    const [displaySupplierDetails, setDisplaySupplierDetails] = useState(true);
    const queryClient = useQueryClient()
    const navigate = useNavigate();

    const createSubVendorMutation = useMutation({
        mutationFn: async () => {
            const {data} = await apollo.mutate({
                mutation: CREATE_SUB_VENDOR, variables: {
                    input: {
                        name,
                        businessName,
                        email,
                        phone,
                        displaySupplierDetails,
                        sharePercentage,
                    }
                },
            });
            return data?.createSubVendor;
        }, onSuccess: () => {
            queryClient.invalidateQueries(['suppliers_list']).then(() => {
                setTimeout(() => navigate("/merchant/sub-vendors"), 2000);
            });
        },
    })

    const handleSubVendorForm = () => {
        if (!name) {
            toast.error('Please provide a name for the sub vendor')
            return
        }
        if (!businessName) {
            toast.error('Please provide a business name for the sub vendor')
            return
        }
        if (!email) {
            toast.error('Please provide an email for the sub vendor')
            return
        }
        if (!phone) {
            toast.error('Please provide a phone number for the sub vendor')
            return
        }
        if (!sharePercentage || isNaN(Number(sharePercentage)) || Number(sharePercentage) < 1 || Number(sharePercentage) > 99) {
            toast.error('Please provide a valid share percentage (numeric value between 1 and 99) for the sub vendor');
            return;
        }
        createSubVendorMutation.mutate()
    }

    return (
        <DashboardLayout>
            <PageHeader back="/merchant/sub-vendors" title="Create Sub Vendor"
                        description="This page provides you with the capability to generate new sub vendors"/>

            <MutationStatusMessage mutation={createSubVendorMutation}/>

            <div className="mt-8 grid gap-x-6 gap-y-8 grid-cols-12">

                <div className="col-span-12 sm:col-span-6 lg:col-span-4">
                    <TextInput name="name" label="Name" id="name" type="text" get={name} set={setName} required={true}/>
                    <p className="text-sm text-gray-500">This is a unique code name to identify the sub vendor</p>
                </div>


                <div className="col-span-12 sm:col-span-6 lg:col-span-4">
                    <TextInput
                        name="sharePercentage"
                        label="Share Percentage"
                        id="sharePercentage"
                        type="number"
                        get={sharePercentage}
                        set={setSharePercentage}
                        required={true}
                    />
                    <p className="text-sm text-gray-500">
                        Specify the percentage of the share the sub-vendor will take for each transaction (e.g., 20%).
                    </p>
                </div>

            </div>

            <div className="mt-8 grid gap-x-6 gap-y-8 grid-cols-12">
                <div className="col-span-12">
                    <div className="bg-yellow-100 text-yellow-800 p-4 rounded-md">
                        <p>
                            The information provided below (Business Name, Email, and Phone) will be displayed on
                            invoices
                            and any related documents generated for this sub-vendor. Please ensure they are accurate.
                        </p>
                    </div>
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-12">
                    <Toggle get={displaySupplierDetails} set={setDisplaySupplierDetails}>Display Supplier
                        Details</Toggle>
                    <p className="text-sm text-gray-500 mt-2">
                        Toggle this option to determine whether the supplier's details will override the merchant's
                        details in invoices or related documents.
                    </p>
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4">
                    <TextInput name="businessName" label="Business Name" id="businessName" type="text"
                               get={businessName} set={setBusinessName} required={true}/>
                    <p className="text-sm text-gray-500">Example: John's Supplies Ltd.</p>
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4">
                    <TextInput name="email" label="Email" id="email" type="text" get={email} set={setEmail}
                               required={true}/>
                    <p className="text-sm text-gray-500">Example: john.supp@gmail.com</p>
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4">
                    <TextInput name="phone" label="Phone" id="phone" type="text" get={phone} set={setPhone}
                               required={true}/>
                    <p className="text-sm text-gray-500">Example: +1-234-567-8900</p>
                </div>

            </div>
            <div className="mt-6 flex flex-wrap items-center gap-4">
                <Button type="button" onClick={handleSubVendorForm}>
                    <span className="flex items-center gap-2">Create Sub Vendor</span>
                </Button>
            </div>
        </DashboardLayout>
    );
}

export default SubVendorCreate;