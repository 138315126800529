import React from 'react';

function FeatureWillBeAvailableSoon() {
	return (
		<div className="flex text-gray-600 gap-2 items-center min-h-[300px] justify-center font-semibold">
			This feature will be available soon
		</div>
	);
}

export default FeatureWillBeAvailableSoon;