import React from 'react';
import TransactionLine from "./components/TransactionLine";

function TransactionList({transactions}) {
    if (!transactions?.length) return null;

    let transactionLines = transactions?.map((transaction) => {
        return <TransactionLine key={transaction?.id} transaction={transaction}/>;
    });
    return (
        <div className="flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                        <tr>
                            <th key="1" scope="col"
                                className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
                                Invoice Ref
                            </th>
                            <th key="2" scope="col"
                                className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
                                Transaction Id
                            </th>
                            <th key="3" scope="col"
                                className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
                                Auth Code
                            </th>
                            <th key="4" scope="col"
                                className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
                                Card Number
                            </th>
                            {/*<th key"1 scope="col" className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
                            Gateway Type</th>*/}
                            <th key="5" scope="col"
                                className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
                                Status
                            </th>
                            <th key="6" scope="col"
                                className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
                                Description
                            </th>
                            {/*<th key"1 scope="col" className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
                            Log</th>*/}
                            <th key="7" scope="col"
                                className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
                                Created At
                            </th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                        {transactionLines}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default TransactionList;