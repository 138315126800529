import React, {useMemo} from 'react';
import {useParams} from "react-router-dom";
import DashboardLayout from "../../../layouts/DashboardLayout";
import Button from "../../../components/Button";
import {useTranslation} from "react-i18next";
import useModalStore from "../../../store/modal";
import {useMakeRefundDecisionMutation} from "../../../utils/mutations";
import {useFetchRefundQuery} from "../../../utils/queries";
import Loading from "../../../components/Loading";
import PageHeader from "../../../components/PageHeader";
import InformationLine from "../../../components/InformationLine";
import StatusBadge from "../../../components/StatusBadge";
import {QueryClient} from "react-query";
import ButtonLink from "../../../components/ButtonLink";

function RefundView() {
    const {refund_uuid} = useParams();
    const {t} = useTranslation();
    const {setModal, clearModal, hideModal} = useModalStore();

    const makeRefundDecisionMutation = useMakeRefundDecisionMutation();
    const fetchRefundQuery = useFetchRefundQuery(refund_uuid);
    const refund = useMemo(() => fetchRefundQuery?.isSuccess ? fetchRefundQuery?.data : null, [fetchRefundQuery]);

    const handleRefundDecision = async (uuid, decision) => {
        // decision: Approve External, Approve, Reject
        let title, description, refundDecision, confirmText;
        switch (decision) {
            case "approve_external":
                title = "Approve external refund request";
                description = "Are you sure you want to approve this refund request? This action cannot be undone.";
                refundDecision = "approve_external";
                confirmText = "Approve External";
                break;
            case "approve":
                title = "Approve refund request";
                description = "Are you sure you want to approve this refund request? This action cannot be undone. The full amount will be refunded to the customer.";
                refundDecision = "approve";
                confirmText = "Approve";
                break;
            case "reject":
                title = "Reject refund request";
                description = "Are you sure you want to reject this refund request? This action cannot be undone.";
                refundDecision = "reject";
                confirmText = "Reject";
                break;
            default:
                title = "Invalid refund request decision";
                description = "The selected refund decision is not recognized. Please choose a valid option.";
                refundDecision = "unknown";
                confirmText = "Unknown";
        }

        setModal({
            isVisible: true,
            title: title,
            description: description,
            otpRequired: false,
            confirmText: confirmText,
            onConfirm: () => {
                makeRefundDecisionMutation.mutate({
                    refundUuid: uuid,
                    refundDecision: refundDecision,
                })
                hideModal();
            },
            onCancel: () => {
                hideModal();
            },
        })
    }

    const parsedFormData = useMemo(() => typeof refund?.formData === 'string' ? JSON.parse(refund.formData) : null, [refund]);

    return (
        <DashboardLayout>
            <PageHeader back="/merchant/refunds" title="Refund View"
                        description="This page provides you with the details of the refund request and allows you to take appropriate actions."/>

            {fetchRefundQuery.isLoading && <Loading/>}
            {fetchRefundQuery.isError && <div>Error loading refund details.</div>}

            {fetchRefundQuery.isSuccess &&
                <div className="mt-6 border-t border-gray-100">
                    <dl className="divide-y divide-gray-100">
                        <InformationLine title="Refund Id" value={refund?.refundUuid}/>

                        <InformationLine title="Payment" value={
                            <ButtonLink secondary={false}
                                        href={`/merchant/payments/view/${refund?.payment?.paymentUuid}`}
                                        target="_blank">
                                View Payment
                            </ButtonLink>
                        }/>
                        <InformationLine title="Payment Status"
                                         value={<StatusBadge status={refund?.payment?.status}/>}/>

                        <InformationLine title="Merchant" value={refund?.merchant?.merchantName || "N/A"}/>
                        <InformationLine title="User" value={refund?.user?.name || "N/A"}/>
                        <InformationLine title="Supplier" value={refund?.supplier?.supplierNname || "N/A"}/>
                        <InformationLine title="Refunded Amount" value={refund?.amount || "N/A"}/>
                        <InformationLine title="Currency" value={refund?.currency?.symbol || "N/A"}/>
                        <InformationLine title="Refund Reference" value={refund?.refundReference || "N/A"}/>
                        <InformationLine title="Reason" value={refund?.reason || "N/A"}/>
                        <InformationLine title="Refunded At" value={refund?.refundedAt || "N/A"}/>

                        <InformationLine title="Refund Status" value={<StatusBadge status={refund?.status}/>}/>
                        {parsedFormData && <InformationLine title="Refund Form" value={
                            <div className="p-4 bg-gray-50 rounded-md shadow-sm">
                                {Object.entries(parsedFormData).map(([key, value]) => (
                                    <div key={key}
                                         className="flex justify-between items-center border-t border-gray-100 py-1 hover:bg-gray-200">
                                        <dt className="font-medium text-gray-700">{key}</dt>
                                        <dd className="text-right text-gray-600">{typeof value === 'object' ? JSON.stringify(value) : value}</dd>
                                    </div>
                                ))}
                            </div>
                        }/>}

                    </dl>

                    <div className="relative whitespace-nowrap py-4 text-sm sm:pr-0 flex gap-2 items-center">
                        {['pending'].includes(refund?.status) && <Button secondary={true}
                                                                         disabled={makeRefundDecisionMutation.isLoading}
                                                                         onClick={() => handleRefundDecision(refund?.refundUuid, 'reject')}>
                            {t('refund.reject')}
                        </Button>}

                        {['pending', 'approved'].includes(refund?.status) &&
                            <Button secondary={false}
                                    disabled={makeRefundDecisionMutation.isLoading}
                                    onClick={() => handleRefundDecision(refund?.refundUuid, 'approve')}>
                                {refund?.status === 'pending' ? t('refund.approve') : t('refund.check_status')}
                            </Button>}

                        {['pending'].includes(refund?.status) &&
                            <Button secondary={false}
                                    disabled={makeRefundDecisionMutation.isLoading}
                                    onClick={() => handleRefundDecision(refund?.refundUuid, 'approve_external')}>
                                {t('refund.approve_external')}
                            </Button>}
                    </div>

                </div>}
        </DashboardLayout>
    );
}

export default RefundView;