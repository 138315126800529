import React from 'react';
import DashboardLayout from "../../../layouts/DashboardLayout";
import FeatureWillBeAvailableSoon from "../../../components/FeatureWillBeAvailableSoon";

function SubUserCreate() {
    return (
        <DashboardLayout>
            <FeatureWillBeAvailableSoon/>
        </DashboardLayout>
    );
}

export default SubUserCreate;