import {useQuery} from "react-query";
import {GET_PAYMENT_DETAILS, GET_REFUND_DETAILS} from "./graphql";
import {apollo} from "./apollo";

export const useFetchPaymentQuery = (paymentUuid) => {
    return useQuery({
        queryKey: ['payment_view', paymentUuid], // Include `paymentUuid` in the query key
        queryFn: async ({ queryKey }) => {
            const [, paymentUuid] = queryKey; // Extract `paymentUuid` from the query key
            const {data} = await apollo.query({
                query: GET_PAYMENT_DETAILS,
                variables: {
                    paymentUuid: paymentUuid,
                },
            });
            return data?.getPaymentDetails;
        },
        enabled: !!paymentUuid,
        keepPreviousData: false,
    });
};


export const useFetchRefundQuery = (refundUuid) => {
    return useQuery({
        queryKey: ['refund_view', refundUuid], // Include `refundUuid` in the query key
        queryFn: async ({ queryKey }) => {
            const [, refundUuid] = queryKey; // Extract `refundUuid` from the query key
            const {data} = await apollo.query({
                query: GET_REFUND_DETAILS,
                variables: {
                    refundUuid: refundUuid,
                },
            });
            return data?.getRefundDetails;
        },
        enabled: !!refundUuid,
        keepPreviousData: false,
    });
};

